import React, { FC, ReactNode } from "react";
import cn from "classnames";

import theme from "./Paragraph.module.scss";

export type ParagraphProps = {
  id?: string;
  className?: string;
  children?: ReactNode;
  size?: ParagraphSize;
};

export type ParagraphSize = "big" | "small" | "regular";

const Paragraph: FC<ParagraphProps> = ({
  size = "regular",
  children,
  className,
  id,
  ...rest
}) => (
  <p id={id} className={cn(className, theme[size])} {...rest}>
    {children}
  </p>
);

export default Paragraph;
