import { all } from "redux-saga/effects";
import { watcherDataSaga as distribution } from "./distribution/distribution.saga";
import { watcherDataSaga as layout } from "./layout/layout.saga";
import { watcherDataSaga as translations } from "./translations/translations.saga";
import { watcherDataSaga as market } from "./market/market.saga";
import { watcherDataSaga as simplePage } from "./simple-page/simple-page.saga";
import { watcherDataSaga as conversions } from "./conversions/conversions.saga";

export default function* rootSaga() {
  yield all([
    distribution(),
    translations(),
    market(),
    layout(),
    simplePage(),
    conversions(),
  ]);
}
