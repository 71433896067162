import { PUBLIC_URL } from "./api";

export const DOWNTIME_IS_ACTIVE: boolean =
  process.env.REACT_APP_DOWNTIME === "true";

export const IMAGES = `${PUBLIC_URL}/images`;

export const DEF_IMAGE = `${IMAGES}/mainimage.png`;
export const DEF_IMAGE_MOBILE = `${IMAGES}/mainimage.png`;
export const DEF_CUSTOM_IMAGE = `${IMAGES}/custom.jpg`;
export const DEF_MESH_IMAGE_CUSTOM = `${IMAGES}/mesh-custom.svg`;

export const DEFAULT_LANGUAGE: string = "en";

export type HTTP_METHODS = "POST" | "GET" | "PUT" | "DELETE";

export const DEFAULT_JSON_HEADER = {
  Accept: "application/json, application/xml, text/plain, text/html, *.*",
  "Content-Type": "application/json",
};

export const DEFAULT_GET_OPTIONS = {
  method: "GET",
  headers: DEFAULT_JSON_HEADER,
};

export const DEFAULT_DELETE_OPTIONS = {
  method: "DELETE",
  headers: DEFAULT_JSON_HEADER,
};

export type COOKIE_STATUS = "accepted" | "rejected";
export const COOKIE_TOKEN = "AcceptCookie";

export const DEF_BUBBLE_INNER_CONTENT = {
  image: "",
  info: "",
  displayedName: "",
};

export const MARKETS = {
  CHINA: "China",
};

export const MARKETS_CODES = {
  CHINA: "CN",
};

export enum CONFIRMIT_DOWNTIME_DATES {
  "END" = "March 6, 2021 18:59:59 UTC",
  "START" = "March 6, 2021 07:00:00 UTC",
}
