import * as constants from "./layout.constants";
import Model from "./layout.model";
import { DataState, Actions } from "./layout.types";

export default function type(state: DataState = Model, { type }: Actions) {
  switch (type) {
    case constants.SET_LAYOUT_RTL:
      return state.merge({
        direction: "rtl",
      });

    case constants.SET_LAYOUT_LTR:
      return state.merge({
        direction: "ltr",
      });

    default:
      return state;
  }
}
