import { put, takeEvery } from "redux-saga/effects";
import * as constants from "./translations.constants";
import { API } from "common/api";
import Translations from "data/Translations";
import { getRequest } from "../utils.saga";
import TranslationService from "common/services/translations";

export function* watcherDataSaga() {
  yield takeEvery(constants.TRANSLATIONS, translationsSaga);
  yield takeEvery(constants.TRANSLATIONS_SET_LOCALE, setLocaleSaga);
}

const giveTranslationError = (
  error: string | object = "Translations not found"
) =>
  put({
    type: constants.TRANSLATIONS_ERROR,
    payload: { error },
  });

export function* translationsSaga({ payload }: any) {
  const { locale } = payload;
  try {
    const response = yield getRequest(API.LOCALE(locale));
    if (!response.ok) {
      return yield giveTranslationError();
    }

    const translations: {
      landing_page: Translations;
    } = yield response.json();

    if (translations) {
      return yield put({
        type: constants.TRANSLATIONS_SUCCESS,
        payload: {
          translations: translations.landing_page,
        },
      });
    }

    return yield giveTranslationError();
  } catch (error) {
    return yield giveTranslationError(error);
  }
}

export function* setLocaleSaga({ payload }: any) {
  return yield TranslationService.setLocalLn(payload?.code);
}
