import { FC, ReactNode, createElement } from "react";
import cn from "classnames";

import theme from "./Heading.module.scss";

interface HeadingProps {
  level: number;
  id?: string;
  className?: string;
  children?: ReactNode;
}

const Heading: FC<HeadingProps> = ({
  children,
  className,
  level,
  id,
  ...rest
}) =>
  createElement(`h${level}`, {
    children,
    className: cn(theme.heading, theme[`h${level}`], className),
    id,
    ...rest,
  });

export default Heading;
