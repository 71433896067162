import * as constants from "./simple-page.constants";
import Model from "./simple-page.model";
import { DataState, Actions } from "./simple-page.types";

export default function simplePage(
  state: DataState = Model,
  { type, payload }: Actions
) {
  switch (type) {
    case constants.SIMPLE_PAGE:
      return state.merge({
        loading: true,
        error: undefined,
        simplePage: undefined,
      });

    case constants.SIMPLE_PAGE_SUCCESS:
      return state.merge({
        loading: false,
        error: undefined,
        simplePage: payload,
      });

    case constants.SIMPLE_PAGE_ERROR:
      return state.merge({
        loading: false,
        error: payload.error,
      });

    default:
      return state;
  }
}
