import { put, takeEvery } from "redux-saga/effects";
import queryString from "query-string";
import * as constants from "./distribution.constants";
import { API } from "common/api";
import { DEFAULT_LANGUAGE } from "common/constants";
import { getRequest } from "../utils.saga";
import { readTranslations } from "../translations/translations.actions";
import { findLn, objToQueryString } from "common/utils";
import GAService from "common/services/googleAnalytics";
import { GADistributionDataInterface } from "common/services/googleAnalytics.constants";

export function* watcherDataSaga() {
  yield takeEvery(constants.DISTRIBUTION, distributionSaga);
}

export function* distributionSaga({ payload }: any) {
  const { name, history, locale, search: searchOverride } = payload;
  try {
    const search = searchOverride ?? history.location.search;
    let url = `${API.DISTRIBUTION(name)}?${objToQueryString(
      queryString.parse(search)
    )}`;
    if (locale) {
      url += `&locale=${locale}`;
    }
    const response = yield getRequest(url);
    if (!response.ok) {
      return yield history.push("/");
    }

    let responseJson = yield response.json();
    if (!responseJson.id) {
      return yield history.push("/");
    }

    yield put({
      type: constants.DISTRIBUTION_SUCCESS,
      payload: responseJson,
    });
    let localLocale = yield findLn(responseJson?.locales)?.code ||
      responseJson?.locale?.code ||
      responseJson?.locales[0]?.code ||
      DEFAULT_LANGUAGE;
    yield readTranslations(localLocale);

    try {
      yield put({ type: constants.GOOGLE_ANALYTICS_BY_DISTRIBUTION });
      const GAResponse = yield getRequest(
        API.GOOGLE_ANALYTICS(responseJson.name)
      );

      if (!GAResponse.ok) return yield giveAnalyticsError(GAResponse);

      yield put({
        type: constants.GOOGLE_ANALYTICS_BY_DISTRIBUTION_SUCCESS,
        payload: yield GAResponse.json(),
      });
      const GAJsonParams: GADistributionDataInterface = yield GAResponse.json();
      yield GAService.setCustomParamsDistribution(GAJsonParams);
      GAService.setCustomParamsPageStatus("starting");
      yield GAService.setPage(
        window.location.pathname + window.location.search
      );
    } catch (error) {
      giveAnalyticsError(error);
    }
  } catch (error) {
    yield history.push("/");
  }
}

const giveAnalyticsError = (error: string | object = "Market not found") =>
  put({
    type: constants.GOOGLE_ANALYTICS_BY_DISTRIBUTION_ERROR,
    payload: { error },
  });
