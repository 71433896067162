import Translations, { CareerTypeDescriptions } from "data/Translations";

export const DEF_SCREENED = {
  cta_button: "Sign me up",
  description:
    "Thank you for taking the time to take the CareerTest until this stage. Unfortunately, the CareerTest is currently not built to assess your career profile. However, please sign up if you’d like to be notified when the CareerTest is available to you again.",
};
export const DEF_DOWNTIME = {
  header: "We’ll be right back!",
  description:
    "We’re performing some maintenance at the moment and will be back soon, sorry for the inconvenience.",
};
export const DEF_HOME_TITLE = "Is your career profile waiting for you?";
export const DEF_HOME_SUBTITLE =
  "See if the CareerTest is live in your location";
export const DEF_HOME_PLACEHOLDER = "Select Location";

const DEF_HERO = {
  title_1: "There are 5 Career Types. Which one are you?",
  header:
    "Take the Universum CareerTest to discover your Career Type and identify employers that fit your profile.",
  cta_button: "Take the test",
  cta_button2: "Find out",
};
const DEF_INTRO = {
  text: "There are five distinct Career Types. Answer questions about your career preferences, goals and personality to discover yours.",
  cta_button: "Find out",
  header: "The Career Types",
};
const DEF_EMPLOYER = {
  header: "Which employers match you best?",
  cta_button: "Find out",
  text: "Find out which employers best fit your profile",
};
const DEF_SALARY = {
  header: "Are you ready to negotiate your salary?",
  text: "Find out how much your peers are expecting to be paid so you can be better equipped in negotiations.",
  cta_button: "Find out",
};
const DEF_HOW_TEST_WORKS_SECTION_TRANSLATIONS = {
  header: "How the test works",
  step1: {
    header: "Take the test",
    text: "The test takes 20 minutes and covers your previous experiences and current preferences to best guide you in your career journey.",
  },
  step2: {
    header: "Get your results",
    text: "Find out which Career Type you are, which employers fit your profile and how your input compares to your peers.",
  },
  step3: {
    header: "Get ready for your career",
    text: "The feedback gives you the tools you need to better understand the job market and to find your dream job.",
  },
  step4: {
    header: "Shape the world around you",
    text: "Your responses help employers become more attractive and relevant to you.",
  },
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const oldCareerTypesDescriptions = {
  careerist: {
    heading: "Careerist",
    info: "Careerists move through their career progression step by step, making the most of each chance to learn new skills.",
  },
  entrepreneur: {
    heading: "Entrepreneur",
    info: "Entrepreneurs thrive in an environment where they have some flexibility to pursue projects that they feel passionate about.",
  },
  harmoniser: {
    heading: "Harmoniser",
    info: "These loyal employees treat their coworkers like family and they are ready to take on additional responsibilities.",
  },
  hunter: {
    heading: "Hunter",
    info: "Passionate and strong, hunters are known to go for what they want and offer creative solutions for complex issues.",
  },
  idealist: {
    heading: "Idealist",
    info: "Idealists don't spend time complaining; They come up with real-world solutions to advance the greater good.",
  },
  internationalist: {
    heading: "Internationalist",
    info: "These individuals want to explore every corner of the globe, and they are happiest in a job that keeps them moving.",
  },
  leader: {
    heading: "Leader",
    info: "While leaders might know business processes inside out and do not fear change, their true strength is seeing the big picture.",
  },
};

const DEF_CAREER_TYPES_DESCRIPTIONS: CareerTypeDescriptions = {
  gogetter: {
    heading: "Go Getter",
    info: "Go-Getters are ambitious, action-oriented and willing to take on tough challenges to achieve great results.",
  },
  globetrotter: {
    heading: "Globe Trotter",
    info: "Globe-Trotters have an international outlook, thrive on diversity and frequent change.",
  },
  groundbreaker: {
    heading: "Ground Breaker",
    info: "Ground-Breakers are team-oriented leaders with a passion for technology and innovation.",
  },
  changemaker: {
    heading: "Change Maker",
    info: "Change-Makers are purpose-driven, equality seekers who believe in serving the greater good.",
  },
  balanceseeker: {
    heading: "Balance Seeker",
    info: "Balance-Seekers are satisfied with secure jobs that enable them to explore their wider life-loving interests to the full.",
  },
};

const DEF_TRANSLATIONS: Translations = {
  login: "Login",
  choose: "Is your career profile waiting for you?",
  choose_subtitle: "See if the CareerTest is live in your location",
  header: "Which career type are you?",
  closure: {
    cta_sect_header: "Where will you work tomorrow?",
    cta_button: "Start",
  },
  cookies: {
    text: "We use cookies in order to optimize your experience using the Universum CareerTest. If you consent to our use of cookies, please click accept. You can read more about our cookies ",
    link: "here.",
    accept: "Accept",
    close: "Close",
  },
  country: "Country",
  hero: DEF_HERO,
  intro: DEF_INTRO,
  employer_recommendations: DEF_EMPLOYER,
  salary_comparisons: DEF_SALARY,
  test_works: DEF_HOW_TEST_WORKS_SECTION_TRANSLATIONS,
  career_type: DEF_CAREER_TYPES_DESCRIPTIONS,
  downtime: DEF_DOWNTIME,
  screened: DEF_SCREENED,
};

export default DEF_TRANSLATIONS;
