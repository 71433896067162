import { put, takeEvery } from "redux-saga/effects";
import * as constants from "./simple-page.constants";
import { API } from "common/api";
import { getRequest } from "../utils.saga";

export function* watcherDataSaga() {
  yield takeEvery(constants.SIMPLE_PAGE, pageSaga);
}

export function* pageSaga({ payload }: any) {
  const { history, id } = payload;
  try {
    const response = yield getRequest(API.SIMPLE_PAGE(id));
    if (!response.ok) {
      return yield history.push("/");
    }

    let responseJson = yield response.json();
    if (!responseJson.uid) {
      return yield history.push("/");
    }

    yield put({
      type: constants.SIMPLE_PAGE_SUCCESS,
      payload: responseJson,
    });
  } catch (err) {
    yield put({
      type: constants.SIMPLE_PAGE_ERROR,
      payload: err,
    });
  }
}
