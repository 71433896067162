import { put, takeEvery } from "redux-saga/effects";
import * as constants from "./layout.constants";
import { LayoutDirections } from "./layout.types";

export function* watcherDataSaga() {
  yield takeEvery(constants.SET_LAYOUT_DIRECTION, layoutSaga);
}

export function* layoutSaga({ payload }: any) {
  yield setLayoutDirection(payload);

  if (payload === "ltr") {
    return yield put({
      type: constants.SET_LAYOUT_LTR,
    });
  }

  return yield put({
    type: constants.SET_LAYOUT_RTL,
  });
}

const setLayoutDirection = (dir: LayoutDirections = "ltr") =>
  document.getElementsByTagName("html")[0].setAttribute("dir", dir);
