import { DEFAULT_LANGUAGE } from "common/constants";
import { changeLayoutDirection } from "store/layout/layout.actions";
import GAService from "./googleAnalytics";

const TranslationService = {
  LANGUAGE_LOCAL_STORE: "career_hub_ln",
  deleteLocalLn() {
    localStorage.removeItem(this.LANGUAGE_LOCAL_STORE);
  },

  getLocalLn() {
    const localLn = localStorage.getItem(this.LANGUAGE_LOCAL_STORE);
    GAService.setCustomParamsLocale(localLn ? localLn : DEFAULT_LANGUAGE);
    return localLn ? localLn : DEFAULT_LANGUAGE;
  },

  getLocalLnWithoutDef() {
    return localStorage.getItem(this.LANGUAGE_LOCAL_STORE);
  },

  setLocalLn(ln: string) {
    changeLayoutDirection(ln);
    GAService.setCustomParamsLocale(ln);
    return localStorage.setItem(this.LANGUAGE_LOCAL_STORE, ln);
  },
};
export default TranslationService;
