import React, { FC } from "react";
import { Suspense, lazy } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { ROOT } from "./Root.constants";
import Loading from "common/ui/loading/Loading";
import FooterContainer from "components/footer/Footer.container";
import AppInit from "../AppInit";
import Styleguide from "../pages/styleguide/Styleguide";

const Home = lazy(() => import("../pages/home/Home"));
const Screened = lazy(() => import("../pages/screened/Screened"));
const Downtime = lazy(() => import("../pages/downtime/Downtime"));
const Landing = lazy(() => import("../pages/landing/Landing"));
const SimplePage = lazy(() => import("../pages/simple-page/SimplePage"));

const Root: FC = () => (
  <Suspense fallback={<Loading />}>
    <Router>
      <AppInit>
        <div className="router">
          <div className="content">
            <Switch>
              <Route exact path={ROOT.HOME} component={Home} />
              <Route
                exact
                path={`${ROOT.LANDING}/:id/:skip_lp?`}
                component={Landing}
              />
              <Route exact path={ROOT.STYLEGUIDE} component={Styleguide} />
              <Route exact path={ROOT.DOWNTIME} component={Downtime} />
              <Route exact path={ROOT.SCREENED} component={Screened} />
              <Route
                exact
                path={`${ROOT.SIMPLE_PAGE}/:id`}
                component={SimplePage}
              />
              <Redirect from="/*" to={ROOT.HOME} />
            </Switch>
          </div>
          <FooterContainer />
        </div>
      </AppInit>
    </Router>
  </Suspense>
);

export default Root;
