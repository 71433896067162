import React, { Component } from "react";
import cn from "classnames";
import ButtonProps from "./Button.interface";

import theme from "./Button.module.scss";

export type ButtonType = "default" | "primary" | "secondary" | "link" | "brick";

export default class Button extends Component<ButtonProps> {
  handleClick = (e: any) => {
    if (this.props.disabled) return;
    if (this.props.onClick) this.props.onClick(e);
  };

  render() {
    const {
      rtl = false,
      children,
      className,
      buttonNativeType,
      type,
      disabled,
      // colors
      black,
      white,
      green,
      grey,
      red,
      blue,
      // sizes
      big,
      small,
      long,
      onClick,
      ...rest
    } = this.props;

    const regularType =
      type === "primary" || type === "secondary" || type === "default";

    return (
      <button
        onClick={this.handleClick}
        className={cn(
          className,
          theme.button,
          type && theme[type],
          disabled && theme.disabled,
          // colors
          green && theme.green,
          black && theme.black,
          white && theme.white,
          grey && theme.grey,
          blue && theme.blue,
          red && theme.red,
          // sizes
          long && theme.long,
          regularType &&
            ((big && theme.big) || (small && theme.small) || theme.regular),
          rtl && theme.rtl
        )}
        type={buttonNativeType}
        {...rest}
      >
        {children}
      </button>
    );
  }
}
