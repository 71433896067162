import React, { FC, useEffect } from "react";
import { readLiveMarkets } from "./store/market/market.actions";
import useCookieConsent from "./common/services/cookieConsent/useCookieConsent";
import GAService from "./common/services/googleAnalytics";
import { GrowthBook, GrowthBookProvider } from "@growthbook/growthbook-react";
import { FEATURES_ENDPOINT } from "common/api";
import { getDeviceId } from "common/utils";

export const growthbook = new GrowthBook({
  trackingCallback: (experiment, result) => {
    sessionStorage.setItem("experimentId", experiment.key);
    sessionStorage.setItem("variationId", result.variationId.toLocaleString());
  },
});

const AppInit: FC = ({ children }) => {
  const { isAcceptedGoogle } = useCookieConsent();

  useEffect(() => {
    readLiveMarkets();
  }, []);

  useEffect(() => {
    if (isAcceptedGoogle) {
      GAService.initGA();
    } else {
      GAService.unmountGA();
    }
  }, [isAcceptedGoogle]);

  useEffect(() => {
    // Load feature definitions from API
    // In production, we recommend putting a CDN in front of the API endpoint
    fetch(FEATURES_ENDPOINT)
      .then((res) => res.json())
      .then((json) => {
        growthbook.setFeatures(json.features);
      });

    const deviceId = getDeviceId();

    // TODO: replace with real targeting attributes
    growthbook.setAttributes({
      deviceId,
      locale: "en", // Default locale. Should be updated when distribution is loaded
    });
  }, []);

  return (
    <GrowthBookProvider growthbook={growthbook}>{children}</GrowthBookProvider>
  );
};

export default AppInit;
