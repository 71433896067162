import React, { FC } from "react";
import cn from "classnames";

import theme from "./Loading.module.scss";

const Loading: FC = () => (
  <div className={theme.root}>
    <div
      className={cn(theme.spinner, theme.spinner3)}
      data-testid="loading-spinner"
    />
  </div>
);

export default Loading;
