import React, { FC, useMemo } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import {
  DEFAULT_POLICY,
  DEFAULT_POLICY_CHINA,
  DEFAULT_TOS,
  DEFAULT_TOS_CHINA,
} from "common/api";
import { MARKETS } from "common/constants";
import Footer from "common/layout/footer/Footer";
import GAService from "common/services/googleAnalytics";
import { CUSTOM_EVENTS_ACTION } from "common/services/googleAnalytics.constants";
import useCookieConsent from "common/services/cookieConsent/useCookieConsent";

function sendFooterEvents(targetLink: string) {
  GAService.sendCustomEvent("click", CUSTOM_EVENTS_ACTION.FOOTER, targetLink);
}

const FooterContainer: FC = () => {
  const { show } = useCookieConsent();
  const currentTime = new Date().getFullYear();

  const location = useLocation();
  const links = useMemo(() => {
    const params = queryString.parse(location.search);
    if (
      params.market?.toString() &&
      params.market.toString() === MARKETS.CHINA
    ) {
      return {
        tosLink: DEFAULT_TOS_CHINA,
        policyLink: DEFAULT_POLICY_CHINA,
      };
    }
    return {
      tosLink: DEFAULT_TOS,
      policyLink: DEFAULT_POLICY,
    };
  }, [location.search]);

  const onClickActions = useMemo(
    () => ({
      onPrivacyPolicy: () => sendFooterEvents("Privacy_Policy"),
      onTermsConditions: () => sendFooterEvents("Terms_Conditions"),
      onCookiePolicy: () => sendFooterEvents("Cookie_Policy"),
      onCareertestMethodology: () => sendFooterEvents("CareerTest_Methodology"),
    }),
    []
  );

  return (
    <Footer
      currentTime={currentTime}
      links={links}
      onClickActions={onClickActions}
      openConsentManager={show}
    />
  );
};

export default FooterContainer;
