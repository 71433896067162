import * as constants from "./layout.constants";
import { store } from "..";
import { DEFAULT_LANGUAGE } from "common/constants";

export enum RTL_LANGUAGES {
  ARABIC = "ar",
  HEBREW = "he",
}

export const changeLayoutDirection = (ln: string = DEFAULT_LANGUAGE) => {
  const oneOfRTLLanguages = Object.values(RTL_LANGUAGES).find((e) => e === ln);

  if (oneOfRTLLanguages) {
    return store.dispatch({
      type: constants.SET_LAYOUT_DIRECTION,
      payload: "rtl",
    });
  }

  return store.dispatch({
    type: constants.SET_LAYOUT_DIRECTION,
    payload: "ltr",
  });
};
