export const PUBLIC_URL = process.env.PUBLIC_URL;
export const BACKEND_SERVER: string =
  process.env.REACT_APP_BACKEND_SERVER || "http://localhost:3004/"; // default value just in case
export const FRONTEND_SERVER: string =
  process.env.REACT_APP_FRONTEND_SERVER || "http://localhost:3004/";

export const TRANSLATION_ROOT = process.env.REACT_APP_TRANSLATIONS_URL;

export const API = {
  MARKETS: `${BACKEND_SERVER}api/markets/`,
  SIMPLE_PAGE: (id: string) =>
    `${BACKEND_SERVER}api/content_cards/simple_pages/${id}`,
  LOCALE: (locale: string) => `${TRANSLATION_ROOT}${locale}/common.json`,
  DISTRIBUTION: (name: string) => `${BACKEND_SERVER}api/distributions/${name}`,
  GOOGLE_ANALYTICS: (distribution_name: string) =>
    `${BACKEND_SERVER}api/distributions/${distribution_name}/analytics`,
  CONVERSIONS: `${BACKEND_SERVER}/api/conversion_events/`,
};

export const DEFAULT_TOS = "https://universumglobal.com/terms-and-conditions/";
export const DEFAULT_TOS_CHINA =
  "https://universumglobal.com/terms-and-conditions/china/";
export const DEFAULT_POLICY = "https://universumglobal.com/privacy-policy/";
export const DEFAULT_POLICY_CHINA =
  "https://universumglobal.com/privacy-policy/china/";
export const DEFAULT_COOKIE = "https://universumglobal.com/cookie-policy/";
export const DEFAULT_METHODOLOGY =
  "https://universumglobal.com/careertest-methodology/";
export const DEFAULT_SCREENED = "http://eepurl.com/ds-4bv";
export const LANDING = "https://ch-api.dev.universumglobal.com/";

const GROWTHBOOK_ENDPOINT = process.env.REACT_APP_GROWTHBOOK_API;
const GROWTHBOOK_API_KEY = process.env.REACT_APP_GROWTHBOOK_KEY;
const GROWTHBOOK_PROJECT_KEY = process.env.REACT_APP_GROWTHBOOK_PROJECT;
export const FEATURES_ENDPOINT = `${GROWTHBOOK_ENDPOINT}/${GROWTHBOOK_API_KEY}?project=${GROWTHBOOK_PROJECT_KEY}`;
