import { put, takeEvery } from "redux-saga/effects";
import * as constants from "./market.constants";
import { API } from "common/api";
import { getRequest } from "../utils.saga";
import { Market } from "data/Market";

export function* watcherDataSaga() {
  yield takeEvery(constants.MARKET, marketSaga);
  yield takeEvery(constants.LIVE_MARKETS, liveMarketSaga);
}

const giveMarketError = (error: string | object = "Market not found") =>
  put({
    type: constants.MARKET_ERROR,
    payload: { error },
  });

export function* marketSaga({ payload }: any) {
  try {
    const response = yield getRequest(`${API.MARKETS}/${payload}`);
    if (!response.ok) return yield giveMarketError();

    const market: { market: Market } = yield response.json();

    if (market) {
      return yield put({
        type: constants.MARKET_SUCCESS,
        payload: market,
      });
    }

    return yield giveMarketError();
  } catch (error) {
    yield put({ type: constants.MARKET_ERROR, payload: { error } });
  }
}

export function* liveMarketSaga() {
  try {
    const response = yield getRequest(API.MARKETS);
    if (!response.ok) return yield giveMarketError();

    const markets: {
      markets: Array<Market>;
    } = yield response.json();

    if (markets)
      return yield put({
        type: constants.LIVE_MARKETS_SUCCESS,
        payload: markets,
      });

    return yield giveMarketError("Could not retrieve all markets");
  } catch (error) {
    return yield giveMarketError(error);
  }
}
