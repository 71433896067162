import * as constants from "./translations.constants";
import { store } from "..";
import Locale from "data/Locale";

export const readTranslations = (locale: string) =>
  store.dispatch({
    type: constants.TRANSLATIONS,
    payload: {
      locale,
    },
  });

export const setLocale = (locale?: Locale) =>
  store.dispatch({
    type: constants.TRANSLATIONS_SET_LOCALE,
    payload: locale,
  });
