import React, { ReactNode, FC } from "react";
import Heading from "./Heading";

interface HeadingChildProps {
  className?: string;
  id?: string;
  children?: ReactNode;
}

const H4: FC<HeadingChildProps> = (props) => <Heading {...props} level={4} />;

export default H4;
