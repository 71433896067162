export const DISTRIBUTION: string = "DISTRIBUTION";
export const DISTRIBUTION_SUCCESS: string = "DISTRIBUTION_SUCCESS";
export const DISTRIBUTION_ERROR: string = "DISTRIBUTION_ERROR";

export const DISTRIBUTION_CLEANUP: string = "DISTRIBUTION_CLEANUP";

export const GOOGLE_ANALYTICS_BY_DISTRIBUTION: string =
  "GOOGLE_ANALYTICS_BY_DISTRIBUTION";
export const GOOGLE_ANALYTICS_BY_DISTRIBUTION_SUCCESS: string =
  "GOOGLE_ANALYTICS_BY_DISTRIBUTION_SUCCESS";
export const GOOGLE_ANALYTICS_BY_DISTRIBUTION_ERROR: string =
  "GOOGLE_ANALYTICS_BY_DISTRIBUTION_ERROR";
