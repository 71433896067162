import { call } from "redux-saga/effects";
import { objToQueryString, getOptions } from "common/utils";

export function* getRequest(url: string, params: any = null): any {
  const urlWithParams: string = yield params
    ? `${url}?${objToQueryString(params)}`
    : url;

  const options = yield getOptions("GET");
  return yield call(fetch, urlWithParams, options);
}

export function* postRequest(url: string, data: any): any {
  let options = yield getOptions("POST");
  options.body = yield JSON.stringify({
    ...data,
  });
  return yield call(fetch, url, options);
}
