import { useCallback, useEffect, useRef, useState } from "react";
import ReactGA from "react-ga";
import throttle from "lodash.throttle";
import {
  customEventTransportParameterInterface,
  CUSTOM_EVENTS_TYPE,
  GADistributionDataInterface,
  PAGES_WITH_SCROLL,
  SCROLL_THRESHOLDS,
} from "./googleAnalytics.constants";

type pageStatusBasedOnSurveyResult =
  | "screened" // for Landing; /not-available page
  | "downtime" // for Landing; /downtime page
  | "starting" // for Landing; distribution page /s/_ID_
  | "complete"; // for MyCareer; result page

const GAService = {
  GA_enabled: false,
  GA_initialised: false,

  // TODO: add memorisation while (GA_enabled === false) then fire all stored events

  initGA: () => {
    if (process.env.REACT_APP_GA_TRACKING_ID) {
      if (!GAService.GA_initialised) {
        ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);
        GAService.GA_initialised = true;
      }
      GAService.GA_enabled = true;
    }
  },
  unmountGA: () => {
    GAService.GA_enabled = false;
  },

  setPage: (page: string) => {
    if (GAService.GA_enabled) {
      ReactGA.pageview(page);
    }
  },

  setCustomParamsDistribution: ({ ...params }: GADistributionDataInterface) => {
    const {
      market,
      surveyName,
      distributionName,
      reid,
      surveyID,
      distributionID,
    } = params;
    if (GAService.GA_enabled) {
      ReactGA.set({ dimension1: surveyID?.replace(/ /g, "_") });
      ReactGA.set({ dimension2: market?.replace(/ /g, "_") });
      ReactGA.set({ dimension6: distributionID?.replace(/ /g, "_") });
      ReactGA.set({ dimension7: surveyName?.replace(/ /g, "_") });
      ReactGA.set({ dimension8: distributionName?.replace(/ /g, "_") });
      ReactGA.set({ dimension9: reid?.replace(/ /g, "_") });
    }
  },

  setCustomParamsPageStatus(status: pageStatusBasedOnSurveyResult) {
    if (GAService.GA_enabled)
      ReactGA.set({ dimension14: status?.replace(/ /g, "_") });
  },

  setCustomParamsLocale(ln: string) {
    if (GAService.GA_enabled)
      ReactGA.set({ dimension5: ln.replace(/ /g, "_") });
  },

  sendCustomEvent(
    category: CUSTOM_EVENTS_TYPE,
    action: string,
    label?: string,
    value?: number,
    nonInteraction?: boolean,
    transport?: customEventTransportParameterInterface
  ) {
    let customEventParameters = {
      category,
      action: action.replace(/ /g, "_"),
    };

    function populateCustomEvent(newParams: object) {
      customEventParameters = {
        ...customEventParameters,
        ...newParams,
      };
    }

    if (label) populateCustomEvent({ label: label.replace(/ /g, "_") });
    if (value) populateCustomEvent({ value });
    if (nonInteraction) populateCustomEvent({ nonInteraction });
    if (transport) populateCustomEvent({ transport });

    if (GAService.GA_enabled) ReactGA.event(customEventParameters);
  },

  /**
   * useAnalyticsScroll React custom hook while initialisin a route
   * Usage:
   *    GAService.useAnalyticsScroll(__action-to-send__);
   */

  useAnalyticsScroll(routeName: PAGES_WITH_SCROLL) {
    // send routeName as action - use page name here
    // send events when user scrolled through 25, 50, 75 and 100 percent of the page:

    const delay = 500; // config delay for throttling scroll events

    const [scrollY, setScrollY] = useState<number>(
      document.body.getBoundingClientRect().top
    ); // use to track Y scroll

    // fire that (throttled - not frequently than 0,5s) function on every srcoll
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const throttled = useCallback(
      throttle(
        () => {
          const rect = document.body.getBoundingClientRect();
          setScrollY(0 - rect.top);
        },
        delay,
        { trailing: true, leading: false }
      ),
      []
    );

    // to remember breakpoints went through inside useEffect
    const lastEvent = useRef(SCROLL_THRESHOLDS.A); // 0%

    // on every breakpoint crossed - check if we went through. if not - send analytics event.
    const confirmAnalyticsEvent = useCallback(
      (scrollBreakpointReached: SCROLL_THRESHOLDS, windowScrolledPercents) => {
        if (
          scrollBreakpointReached > lastEvent.current &&
          windowScrolledPercents >= scrollBreakpointReached * 25
        ) {
          GAService.sendCustomEvent(
            "scroll",
            `${scrollBreakpointReached * 25}% scrolled`,
            routeName
          );
          lastEvent.current = scrollBreakpointReached;
        }
      },
      [routeName]
    );

    // fires only on throttled scroll. every time check how much we scrolled and which breakpoints crossed
    useEffect(() => {
      const rect = document.body.getBoundingClientRect();
      const windowScrolledPercents = Math.abs(
        Math.round((rect.top / (rect.height - window.innerHeight)) * 100)
      );

      // check if we haven't sent analytics on current scroll position (or lower)
      const eventShortcut = (breakpoint: SCROLL_THRESHOLDS) =>
        confirmAnalyticsEvent(breakpoint, windowScrolledPercents);
      // and if we reached certain breakpoint. from biggest to lowest
      // if yes - send analytics event and remember what we have already sent
      // => lower breakpoints won't fire if bigger went through

      eventShortcut(SCROLL_THRESHOLDS.E); /* 100% */
      eventShortcut(SCROLL_THRESHOLDS.D); /* 75% */
      eventShortcut(SCROLL_THRESHOLDS.C); /* 50% */
      eventShortcut(SCROLL_THRESHOLDS.B); /* 25% */
    }, [scrollY, confirmAnalyticsEvent]);

    // when route is mounted - add scroll listener.
    // and destroy listener when unmounted
    useEffect(() => {
      window.addEventListener("scroll", throttled);
      return () => window.removeEventListener("scroll", throttled);
    });
  },
};

export default GAService;
