import * as constants from "./distribution.constants";
import Model from "./distribution.model";
import { DataState, Actions } from "./distribution.types";

export default function distribution(
  state: DataState = Model,
  { type, payload }: Actions
) {
  switch (type) {
    case constants.DISTRIBUTION:
      return state.merge({
        loading: true,
        error: undefined,
        distribution: undefined,
      });

    case constants.DISTRIBUTION_SUCCESS:
      return state.merge({
        loading: false,
        error: undefined,
        distribution: payload,
      });

    case constants.DISTRIBUTION_ERROR:
      return state.merge({
        loading: false,
        error: payload.error,
      });
    case constants.DISTRIBUTION_CLEANUP:
      return state.merge({
        distribution: undefined,
      });

    case constants.GOOGLE_ANALYTICS_BY_DISTRIBUTION_SUCCESS:
      return state.merge({
        analytics: payload,
      });
    default:
      return state;
  }
}
