import * as constants from "./market.constants";
import { store } from "..";

export const readMarket = (id: number) =>
  store.dispatch({
    type: constants.MARKET,
    payload: id,
  });

export const readLiveMarkets = () =>
  store.dispatch({
    type: constants.LIVE_MARKETS,
  });
