import React, { FC } from "react";
import { H1, H2, H3, H4, P1, P2, P } from "common/ui/typography";
import {
  ButtonPrimary,
  ButtonDefault,
  ButtonSecondary,
  ButtonLink,
  ButtonBrick,
} from "common/ui/button";

const Styleguide: FC = () => {
  const style_row = { display: "inline-block", marginRight: "20px" };
  const style_darkBg = { background: "black", padding: "10px" };
  const style_lightBg = { background: "transparent", padding: "10px" };
  const style_spacing = { marginTop: "50px" };

  return (
    <div className="container" style={style_spacing}>
      <H1>Lorem ipsum</H1>
      <H2>Lorem ipsum</H2>
      <H3>Lorem ipsum</H3>
      <H4>Lorem ipsum</H4>

      <br />

      <P1>
        1 Lorem ipsum, dolor sit amet consectetur adipisicing elit. Iusto
        sapiente dolores, adipisci incidunt tenetur similique consectetur odio
        sint saepe nulla, <a href="/">atque excepturi deleniti accusamus</a>{" "}
        blanditiis non veritatis culpa nemo ipsa.
      </P1>
      <P>
        2 Lorem ipsum, dolor sit amet consectetur adipisicing elit. Iusto
        sapiente dolores, adipisci incidunt tenetur similique consectetur odio
        sint saepe nulla, <a href="/">atque excepturi deleniti accusamus</a>{" "}
        blanditiis non veritatis culpa nemo ipsa.
      </P>
      <P2>
        3 Lorem ipsum, dolor sit amet consectetur adipisicing elit. Iusto
        sapiente dolores, adipisci incidunt tenetur similique consectetur odio
        sint saepe nulla, <a href="/">atque excepturi deleniti accusamus</a>{" "}
        blanditiis non veritatis culpa nemo ipsa.
      </P2>

      <div style={{ ...style_row, ...style_spacing }}>
        <div style={style_darkBg}>
          <ButtonDefault>Button</ButtonDefault>
          <ButtonDefault small>Button</ButtonDefault>
          <ButtonDefault big>Button</ButtonDefault>
          <ButtonDefault disabled>Button</ButtonDefault>
        </div>
      </div>
      <div style={style_row}>
        <div style={style_lightBg}>
          <ButtonPrimary>Button</ButtonPrimary>
          <ButtonPrimary small>Button</ButtonPrimary>
          <ButtonPrimary big>Button</ButtonPrimary>
          <ButtonPrimary disabled>Button</ButtonPrimary>
        </div>
      </div>
      <div style={style_row}>
        <div style={style_lightBg}>
          <ButtonSecondary>Button</ButtonSecondary>
          <ButtonSecondary small>Button</ButtonSecondary>
          <ButtonSecondary big>Button</ButtonSecondary>
          <ButtonSecondary disabled>Button</ButtonSecondary>
        </div>
      </div>

      <div style={style_spacing}>
        <ButtonLink>Button</ButtonLink>
        <ButtonLink small>Button</ButtonLink>
        <ButtonLink big>Button</ButtonLink>
        <ButtonLink disabled>Button</ButtonLink>
      </div>
      <div style={{ ...style_spacing, ...style_darkBg }}>
        <ButtonBrick>Button</ButtonBrick>
        <ButtonBrick small>Button</ButtonBrick>
        <ButtonBrick big>Button</ButtonBrick>
        <ButtonBrick disabled>Button</ButtonBrick>
      </div>

      <div style={style_spacing}>
        <ButtonPrimary long>Button</ButtonPrimary>
        <ButtonPrimary long small>
          Button
        </ButtonPrimary>
        <ButtonPrimary long big>
          Button
        </ButtonPrimary>
        <ButtonPrimary long disabled>
          Button
        </ButtonPrimary>
      </div>

      <div style={style_spacing}>
        <ButtonPrimary>
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Natus minima
          excepturi laboriosam eos eligendi, dolor doloremque ducimus vel
          tempora vitae neque porro, cum recusandae repudiandae corporis
          tempore, nesciunt quia eaque.
        </ButtonPrimary>
      </div>

      <div style={{ ...style_row, ...style_spacing }}>
        <div style={style_lightBg}>
          <ButtonDefault black>Button</ButtonDefault>
        </div>
        <div style={style_darkBg}>
          <ButtonDefault green>Button</ButtonDefault>
          <ButtonDefault grey>Button</ButtonDefault>
          <ButtonDefault blue>Button</ButtonDefault>
          <ButtonDefault red>Button</ButtonDefault>
          <ButtonDefault white>Button</ButtonDefault>
        </div>
      </div>
      <div style={style_row}>
        <div style={style_darkBg}>
          <ButtonSecondary white>Button</ButtonSecondary>
        </div>
        <div style={style_lightBg}>
          <ButtonSecondary black>Button</ButtonSecondary>
          <ButtonSecondary green>Button</ButtonSecondary>
          <ButtonSecondary grey>Button</ButtonSecondary>
          <ButtonSecondary blue>Button</ButtonSecondary>
          <ButtonSecondary red>Button</ButtonSecondary>
        </div>
      </div>
      <div style={style_row}>
        <div style={style_darkBg}>
          <ButtonPrimary white>Button</ButtonPrimary>
        </div>
        <div style={style_lightBg}>
          <ButtonPrimary black>Button</ButtonPrimary>
          <ButtonPrimary green>Button</ButtonPrimary>
          <ButtonPrimary grey>Button</ButtonPrimary>
          <ButtonPrimary blue>Button</ButtonPrimary>
          <ButtonPrimary red>Button</ButtonPrimary>
        </div>
      </div>
    </div>
  );
};

export default Styleguide;
