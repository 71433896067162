import { takeLatest } from "redux-saga/effects";
import * as constants from "./conversions.constants";
import {
  ConversionEventAction,
  ConversionEventPayload,
} from "./conversions.interface";
import { postRequest } from "../utils.saga";
import { API } from "common/api";
import { getDeviceId, getExperimentId, getVariationId } from "common/utils";

export function* watcherDataSaga() {
  yield takeLatest(constants.OPENED_LANDING_PAGE, openedLandingSaga);
  yield takeLatest(constants.STARTED_SURVEY, startedSurveySaga);
}

export function* openedLandingSaga({ payload }: ConversionEventAction): any {
  const event: ConversionEventPayload = {
    locale: payload.locale,
    distribution: payload.distributionId,
    device_id: getDeviceId(),
    experiment_id: getExperimentId(),
    variation_id: getVariationId(),
    type: "opened_landing_page",
    occurred_at: new Date(),
  };
  try {
    yield postRequest(API.CONVERSIONS, event);
  } catch (err) {
    console.log("Conversion service error", err);
  }
}

export function* startedSurveySaga({ payload }: ConversionEventAction): any {
  const event: ConversionEventPayload = {
    locale: payload.locale,
    distribution: payload.distributionId,
    type: "started_survey",
    occurred_at: new Date(),
    device_id: getDeviceId(),
    experiment_id: getExperimentId(),
    variation_id: getVariationId(),
  };
  try {
    yield postRequest(API.CONVERSIONS, event);
  } catch (err) {
    console.log("Conversion service error", err);
  }
}
