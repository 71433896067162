import { combineReducers } from "redux";
import distribution from "./distribution/distribution.reducer";
import translations from "./translations/translations.reducer";
import market from "./market/market.reducer";
import layout from "./layout/layout.reducer";
import simplePage from "./simple-page/simple-page.reducer";

export default combineReducers({
  distribution,
  translations,
  market,
  layout,
  simplePage,
});
