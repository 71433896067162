import {
  HTTP_METHODS,
  DEFAULT_JSON_HEADER,
  COOKIE_STATUS,
  COOKIE_TOKEN,
} from "./constants";
import Locale from "data/Locale";
import { SelectItem } from "./ui/select/Select.interface";
import { Distribution } from "data/Distribution";
import { CustomSection } from "data/CustomSection";
import { Market } from "data/Market";
import TranslationService from "./services/translations";
import { v4 as GUID } from "uuid";

export const getSelectItemFromLocale = ({
  name,
  code,
}: Locale): SelectItem => ({
  label: name,
  value: code,
});

export const getSelectItemFromMarket = ({
  name,
  default_distribution_name,
}: Market): SelectItem => ({
  value: default_distribution_name || "",
  label: name,
});

export const getLocaleFromDistribution = ({ locales, locale }: Distribution) =>
  findLn(locales) || locale || locales[0];

export const findLn = (locales: Locale[]): Locale | undefined => {
  const ln = TranslationService.getLocalLnWithoutDef();
  return locales.find(({ code }) => code === ln);
};

export const getLocalCookie = () => localStorage.getItem(COOKIE_TOKEN);
export const deleteLocalCookie = () => localStorage.removeItem(COOKIE_TOKEN);
export const setLocalCookie = (cookie: COOKIE_STATUS) =>
  localStorage.setItem(COOKIE_TOKEN, cookie);

export function getOptions(type: HTTP_METHODS) {
  return {
    method: type,
    headers: DEFAULT_JSON_HEADER,
  };
}

export function objToQueryString(obj: any) {
  const keyValuePairs = [];
  for (const key in obj) {
    keyValuePairs.push(
      `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`
    );
  }

  return keyValuePairs.join("&");
}

export function timeConvert(updated: Date): string {
  return `${updated.getMonth()}/${updated.getDate()}/${updated.getFullYear()} ${updated.getHours()}:${updated.getMinutes()}`;
}

export function isBetween(date: Date, min: Date, max: Date) {
  return date.getTime() >= min.getTime() && date.getTime() <= max.getTime();
}

export const findSectionByLocaleId = (
  distribution: Distribution,
  id: number
): CustomSection | undefined =>
  distribution.custom_sections.find(({ locale }) => locale === id);

export const sortStringsByLabel = (a: any, b: any) => {
  const nameA = a.label.toUpperCase();
  const nameB = b.label.toUpperCase();

  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }

  // names must be equal
  return 0;
};

export const getDeviceId = (): string => {
  const deviceId = localStorage.getItem("deviceId");
  if (deviceId) return deviceId;

  const newDeviceId = GUID();
  localStorage.setItem("deviceId", newDeviceId);

  return getDeviceId();
};

export const getExperimentId = () =>
  sessionStorage.getItem("experimentId") ?? undefined;

export const getVariationId = () =>
  sessionStorage.getItem("variationId") ?? undefined;
