import React, { ReactNode, FC } from "react";
import Heading from "./Heading";

interface HeadingChildProps {
  className?: string;
  id?: string;
  children?: ReactNode;
}

const H2: FC<HeadingChildProps> = (props) => <Heading {...props} level={2} />;

export default H2;
