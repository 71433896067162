import { useState, useEffect } from "react";
export const useCookieConsent = () => {
  const [isAcceptedGoogle, setIsAcceptedGoogle] = useState<boolean>(false);

  const manager = (window as any).klaro?.getManager();

  function onConsentUpdated(
    obj: any,
    name: "applyConsents" | "saveConsents" | "consents"
  ) {
    if (name === "applyConsents") {
      setIsAcceptedGoogle(obj.consents.googleAnalytics);
    }
  }

  const show = () => (window as any).klaro?.show();

  useEffect(() => {
    const watcher = {
      update: onConsentUpdated,
    };

    const manager = (window as any).klaro?.getManager();
    manager?.watch(watcher);

    return () => {
      manager?.unwatch(watcher);
    };
  }, [manager]);

  useEffect(() => {
    const { googleAnalytics } = manager?.consents || {};
    setIsAcceptedGoogle(Boolean(googleAnalytics));
  }, [manager?.consents]);

  return {
    isAcceptedGoogle,
    show,
  };
};

export default useCookieConsent;
