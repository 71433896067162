import * as constants from "./translations.constants";
import Model from "./translations.model";
import { DataState, Actions } from "./translations.types";
import DEF_TRANSLATIONS from "./translations.default";

export default function translations(
  state: DataState = Model,
  { type, payload }: Actions
) {
  switch (type) {
    case constants.TRANSLATIONS:
      return state.merge({
        loading: true,
        error: undefined,
      });

    case constants.TRANSLATIONS_SUCCESS:
      return state.merge({
        loading: false,
        error: undefined,
        translations: payload.translations,
      });

    case constants.TRANSLATIONS_ERROR:
      return state.merge({
        loading: false,
        translations: DEF_TRANSLATIONS,
        error: payload.error,
      });

    case constants.TRANSLATIONS_SET_LOCALE:
      return state.merge({
        currentLocale: payload,
      });

    default:
      return state;
  }
}
