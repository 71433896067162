import * as constants from "./market.constants";
import Model from "./market.model";
import { DataState, Actions } from "./market.types";

export default function type(
  state: DataState = Model,
  { type, payload }: Actions
) {
  switch (type) {
    case constants.MARKET:
      return state.merge({
        loading: true,
        error: undefined,
      });

    case constants.MARKET_SUCCESS:
      return state.merge({
        loading: false,
        error: undefined,
        market: payload.market,
      });

    case constants.LIVE_MARKETS:
      return state.merge({
        loading: true,
        error: undefined,
      });

    case constants.LIVE_MARKETS_SUCCESS:
      return state.merge({
        loading: false,
        error: undefined,
        liveMarkets: payload,
      });

    case constants.MARKET_ERROR:
      return state.merge({
        loading: false,
        error: payload.error,
      });

    default:
      return state;
  }
}
