import React, { FC } from "react";
import cn from "classnames";
import { DEFAULT_COOKIE, DEFAULT_METHODOLOGY } from "common/api";
import { P2 } from "../../ui/typography";
import { ButtonLink } from "../../ui/button";
import { FooterLayoutInterface } from "./Footer.interface";

import theme from "./Footer.module.scss";

const Footer: FC<FooterLayoutInterface> = ({
  currentTime,
  links: { tosLink, policyLink },
  onClickActions: {
    onTermsConditions,
    onPrivacyPolicy,
    onCookiePolicy,
    onCareertestMethodology,
  },
  openConsentManager,
}) => (
  <footer className={theme.footer}>
    <div className={cn("container", theme.content)}>
      <div className={theme.menu}>
        <a
          className={theme.link}
          onClick={onTermsConditions}
          href={tosLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          Terms & Conditions
        </a>
        <a
          className={theme.link}
          onClick={onPrivacyPolicy}
          href={policyLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </a>
        <a
          className={theme.link}
          onClick={onCookiePolicy}
          href={DEFAULT_COOKIE}
        >
          Cookie Policy
        </a>
        <a
          className={theme.link}
          onClick={onCareertestMethodology}
          href={DEFAULT_METHODOLOGY}
        >
          Careertest Methodology
        </a>
        <ButtonLink className={theme.link} onClick={openConsentManager}>
          Cookie Settings
        </ButtonLink>
      </div>
      <P2 className={theme.text}>
        © {currentTime} Universum Communications Sweden AB, company registration
        no. 5565875993, 103 86 Stockholm. All Rights Reserved.
      </P2>
    </div>
  </footer>
);

export default Footer;
