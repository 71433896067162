export type GADistributionDataInterface = {
  surveyID: string;
  surveyName: string;
  distributionID: string;
  distributionName: string;
  market: string;
  reid: string;
};

export type customEventTransportParameterInterface = "beacon" | "xhr" | "image";
export type CUSTOM_EVENTS_TYPE = "click" | "scroll";

export enum CUSTOM_EVENTS_ACTION {
  SPIDER_WEB_ITEM = "Spider Web Item - Open",
  SPIDER_WEB_POPUP_CLOSE = "Spider Web Popup - Close",
  START_TEST_SPIDER_WEB = "Start Test - Spider Web Popup",

  START_TEST = "Start Test",
  FOOTER = "Footer",

  DROPDOWN_MARKET_HOME = "Choose market - dropdown on /",
  DROPDOWN_MARKET = "Choose market - dropdown on /s/",
  DROPDOWN_LOCALE = "Choose locale - dropdown on /s/",

  LOGIN_HOMEPAGE = "Login Homepage",
}

export enum CUSTOM_EVENTS_DEFAULT_LABELS {
  START_TEST_MAIN = "Main CTA",
  START_TEST_CUSTOM_SECTION = "Custom Section",
  START_TEST_HOW_TEST_WORKS = "How Test Works",
}

export enum PAGES_WITH_SCROLL {
  LANDING = "Landing Page Scroll",
}

export enum SCROLL_THRESHOLDS {
  A = 0,
  B = 1,
  C = 2,
  D = 3,
  E = 4,
}
